import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "c-form-radio control radio" }
const _hoisted_2 = { class: "radio-label" }
const _hoisted_3 = { class: "radio-options" }
const _hoisted_4 = ["checked", "onInput"]
const _hoisted_5 = { class: "radio-option-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createElementBlock("label", {
          class: "radio",
          key: index
        }, [
          _createElementVNode("input", {
            type: "radio",
            name: "answer",
            checked: _ctx.modelValue === option.value,
            onInput: ($event: any) => (_ctx.$emit('update:modelValue', option.value))
          }, null, 40, _hoisted_4),
          _createElementVNode("span", _hoisted_5, _toDisplayString(option.label), 1)
        ]))
      }), 128))
    ])
  ]))
}