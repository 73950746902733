
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import CSpinner from "@/components/spinner.vue";
import CFormRadio from "@/components/form/radio.vue";
import CDropdown, { DropdownItem } from "@/components/dropdown.vue";
import { ObInputSearch } from "@/libs/components";

import { Specialist } from "@/store/specialist/types";
import { formatDate, formatDateEn, formatDateLong, formatFullDateLong, addMoneyMask } from "@/utils/formatters";
import { sexFilterOptions } from "@/utils";

const VConsultationSpecialist = defineComponent({
  name: "VConsultationSpecialist",
  components: { CDropdown, CFormRadio, CSpinner, ObInputSearch },
  setup() {
    useHead({ title: "Especialistas | obmed" });
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isPrimary = route.name === "consultation-specialist";

    const allSpecialist = computed(() => store.state.specialist.list);
    const allSpecialistByDate = computed(() => store.state.specialist.dateList);
    const allSpecialistList = computed(() =>
      isPrimary ? allSpecialist.value : selectedDate.value ? allSpecialistByDate.value[selectedDate.value] : []
    );

    const specialistSex = ref<"M" | "F" | null>(null);
    const specialistOrder = ref<true | false>(true);

    const selectedDate = ref<string | null>(String(route.query.date) || null);
    const specialistRoute = computed(() => (specialist: Specialist) => {
      if (isPrimary) {
        if (!specialist.cd_especialidade?.length) {
          store.commit("setToast", [{ summary: "O especialista não possui especialidade", severity: "warn" }]);

          return route;
        }

        if (specialist.cd_especialidade.length > 1)
          return {
            name: "consultation-specialist-specialty",
            params: { _id: specialist.id },
            query: { ...route.query },
          };

        return {
          name: "consultation-establishment",
          params: { specialty: specialist.cd_especialidade[0].nm_especialidade?.toLowerCase().replaceAll(" ", "-") },
          query: { ...route.query, sy: specialist.cd_especialidade[0].id, st: specialist.id },
        };
      }

      return {
        name: "consultation-add",
        params: { specialist: specialist.nm_especialista?.toLowerCase().replaceAll(" ", "-") },
        query: {
          ...route.query,
          st: specialist.id,
          hr: specialist.hr_horario?.length ? specialist.hr_horario[0]?.id : 0,
        },
      };
    });

    const search = ref("");
    const loading = reactive({ list: false });
    const orderDropdownItems = computed<DropdownItem[]>(() => [
      {
        label: "Nome A-Z",
        isActive: specialistOrder.value,
        command() {
          specialistOrder.value = true;
        },
      },
      {
        label: "Sem ordenação",
        isActive: !specialistOrder.value,
        command() {
          specialistOrder.value = false;
        },
      },
    ]);

    function handleSelectedDate(date: string) {
      selectedDate.value = formatDate(date);
    }

    async function getAllConsultationSpecialist() {
      loading.list = true;
      await store.dispatch("getAllSpecialist", {
        ie_sexo: specialistSex.value,
        ie_ordem_alfabetica: specialistOrder.value,
      });
      loading.list = false;
    }

    async function getAllConsultationSpecialistByDate() {
      loading.list = true;
      await store.dispatch("getAllSpecialistByDate", {
        specialtyID: String(route.query.sy),
        establishmentID: String(route.query.et),
        date: formatDateEn(String(selectedDate.value)),
        ie_sexo: specialistSex.value,
        ie_ordem_alfabetica: specialistOrder.value,
      });
      loading.list = false;
    }

    async function getInitialConsultationSpecialistByDate() {
      loading.list = true;
      await Promise.allSettled([
        store.dispatch("getAllSpecialistByDate", {
          specialtyID: String(route.query.sy),
          establishmentID: String(route.query.et),
          date: formatDateEn(String(selectedDate.value)),
        }),
      ]);
      loading.list = false;
    }

    async function redirectUser() {
      await getInitialConsultationSpecialistByDate();

      if (selectedDate.value) {
        const specialist = allSpecialistByDate.value[selectedDate.value]?.find(
          (specialist) => specialist.id === Number(route.query.st)
        );

        if (specialist) {
          const route = specialistRoute.value(specialist);
          router.replace({ name: String(route.name), params: Object(route.params), query: Object(route.query) });
        }
      }
    }

    if (isPrimary) getAllConsultationSpecialist();
    else if (!Number(route.query.sy) || !Number(route.query.et) || !route.query.date)
      router.replace({ name: "consultation" });
    else if (Number(route.query.st)) redirectUser();
    else getInitialConsultationSpecialistByDate();

    watch(selectedDate, async (date) => {
      await getAllConsultationSpecialistByDate();
      router.replace({ query: { ...route.query, date } });
    });

    watch(
      [specialistSex, specialistOrder],
      isPrimary ? getAllConsultationSpecialist : getAllConsultationSpecialistByDate
    );

    return {
      ...{ isPrimary, allSpecialist, allSpecialistByDate, allSpecialistList, selectedDate },
      ...{ search, loading, specialistSex, specialistOrder, orderDropdownItems },
      ...{ handleSelectedDate, specialistRoute, sexFilterOptions },
      ...{ formatDate, formatDateLong, formatFullDateLong, addMoneyMask },
    };
  },
});

export default VConsultationSpecialist;
