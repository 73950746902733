
import { defineComponent, PropType } from "vue";

const CFormRadio = defineComponent({
  name: "CFormRadio",
  emits: ["update:modelValue"],
  props: {
    options: { type: Array as PropType<{ label: string; value: any }[]>, required: true },
    modelValue: { required: true },
  },
});

export default CFormRadio;
